import React from 'react'
import { graphql, useStaticQuery } from 'gatsby'
import { Helmet } from 'react-helmet'
import Layout from '../../components/layout'
import SEO from '../../components/seo'
import {
  generateFaqSchema,
  generateOrgSchema,
  generatePokerReviewSchemaAndroid,
  generatePokerReviewSchemaIos,
  generateRummyReviewSchemaAndroid,
  generateRummyReviewSchemaIos,
  generateWebsiteSchema,
} from '../../lib/generate-schema'
import defaultFaqs, { faqsHindi } from '../../components/FAQs/faqs-list'
import { HOME_PAGE } from '../../components/download-links'

import {
  pokerOneLinksHomePage,
  rummyOneLinksHomePage,
} from '../../components/one-links'

import { HINDI_HOME } from '../../components/internal-links'
import loadable from '@loadable/component'

const MainBanner = loadable(
  () => import('../../components/Design2.0/HomePage/MainBanner/MainBanner')
)
const TrustFlowWidget = loadable(
  () => import('../../components/Trust-Flow-Widget/Trust-flow-widget')
)
const PokerFeatures = loadable(
  () => import('../../components/Design2.0/HomePage/Features')
)
const WhyChooseGetMega = loadable(
  () =>
    import(
      '../../components/Design2.0/common/WhyChooseGetMega/WhyChooseGetMega'
    )
)
const StaySafeAndCertified = loadable(
  () =>
    import(
      '../../components/Design2.0/common/StaySafeAndCertified/StaySafeAndCertified'
    )
)
const AboutGetMega = loadable(
  () => import('../../components/Design2.0/HomePage/AboutGetMega/AboutGetMega')
)
const OurBlogs = loadable(
  () => import('../../components/Design2.0/common/OurBlogs/OurBlogs')
)
const TestimonialsContent = loadable(
  () => import('../../components/Design2.0/common/Testimonials/Testimonials')
)
const HomeFAQs = loadable(
  () => import('../../components/Index/HomeFAQs/HomeFAQs')
)

declare global {
  interface Window {
    trackGLDownloadEvent: () => void
  }
}

const IndexPage: React.FC = () => {
  const data = useStaticQuery(graphql`
    {
      site {
        siteMetadata {
          siteUrl
        }
      }
    }
  `)
  const SITE_URL = data.site.siteMetadata.siteUrl

  return (
    <Layout
      headerStyles={{
        backgroundColor: 'transparent',
      }}
      apkLink={HOME_PAGE}
      showPokerForm
      showRummyForm
      pokerOneLink={pokerOneLinksHomePage}
      rummyOneLink={rummyOneLinksHomePage}
      lang="hindi"
      pageName="/"
      langPost={[
        {
          languageName: 'english',
          pageSlug: `/`,
        },
        {
          languageName: 'hindi',
          pageSlug: `${HINDI_HOME}`,
        },
      ]}
    >
      <SEO
        title="भारत में खेलें ऑनलाइन  रमी और पोकर  रियल कैश जीतें | 1 करोड़ तक जीतें | Mega"
        description="Mega पर खेलें रमी और पोकर भारत का सबसे लोकप्रिय रियल मनी गेमिंग प्लेटफॉर्म।  रोमांचक रियल कैश जीतें - जी हा प्रति सप्ताह ₹500,000 तक जीतें"
        organizationSchema={generateOrgSchema()}
        websiteSchema={generateWebsiteSchema()}
        reviewSchemaPokerAndroid={generatePokerReviewSchemaAndroid()}
        reviewSchemaPokerIos={generatePokerReviewSchemaIos()}
        reviewSchemaRummyAndroid={generateRummyReviewSchemaAndroid()}
        reviewSchemaRummyIos={generateRummyReviewSchemaIos()}
        faqSchema={generateFaqSchema(defaultFaqs)}
        hideHrefLang
      />
      <Helmet>
        <link rel="alternate" href={SITE_URL} hrefLang="x-default" />
        <link rel="alternate" href={SITE_URL} hrefLang="en-in" />
        <link
          rel="alternate"
          href={`${SITE_URL}${HINDI_HOME}`}
          hrefLang="hi-in"
        />
      </Helmet>

      <MainBanner lang="hindi" />
      <TrustFlowWidget lang="hindi" />
      <PokerFeatures lang={'hindi'} />
      <WhyChooseGetMega lang={'hindi'} />
      <StaySafeAndCertified lang="hindi" />
      <TestimonialsContent lang="hindi" />
      <HomeFAQs
        faqTitle="पूछे जाने वाले प्रश्न"
        faqs={faqsHindi.slice(0, 5)}
        allFaqText="एफएक्यू पेज पर जाएं"
      />
      <OurBlogs lang={'hindi'} />

      <AboutGetMega lang="hindi" />
    </Layout>
  )
}

export default IndexPage
